<template>
  <div class="scs-workload-item">
    <span
      :style="`background-color: ${color}`"
    ></span>

    {{ name }}
  </div>
</template>

<script>
export default {
  name: 'WorkloadItem',
  props: {
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  .scs-workload-item {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: #323232;
    margin-right: 10px;

    span {
      width: 9px;
      height: 9px;
    }
  }
</style>
