<template>
  <label>
    <div>
      {{ placeholder }}
      <template v-if="required">
        <span>*</span>
      </template>
    </div>

    <input
      :type="type"
      :name="name"
      :placeholder="placeholder"
      :required="required"
      :value="value"
      v-mask="mask"
      @input="updateInput($event.target.value)"
    />
  </label>
</template>

<script>
export default {
  name: 'Input',
  props: {
    name: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      required: true,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    mask: {
      type: String,
      default: '',
    },
  },
  methods: {
    updateInput(val) {
      this.$emit('input', val);
    },
  },
};
</script>

<style lang="scss" scoped>
  label {
    display: block;
    color: #181818;

    input {
      border: 1px solid #CECECE;
      border-radius: 5px;
      width: 100%;
      height: 52px;
      padding: 5px 15px;
      background: #fff;
      margin: 0;
      outline: none;
      font-size: 16px;
      line-height: 22px;
    }

    & > div {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 10px;

      span {
        color: red;
      }
    }
  }
</style>
