<template>
  <div class="scs-time-radio">
    <template v-for="(item, index) in list">
      <label
        class="scs-radio"
        :key="`radio-type-${index}`"
      >
        <input
          type="radio"
          name="time"
          :value="item.name"
          :disabled="item.disabled"
          :checked="(item.name === value)"
          @input="(!item.disabled) ? $emit('input', item.name) : ''"
        >

        <span
          :style="`background-color: ${item.color};`"
        >{{ item.name }}</span>
      </label>
    </template>
  </div>
</template>

<script>
export default {
  name: 'RadioTime',
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    list: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  .scs-time-radio {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
    gap: 10px;

    @media (max-width: 575px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .scs-radio {
    cursor: pointer;
    position: relative;
    margin-bottom: 0;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      color: #181818;
      border-radius: 5px;
      height: 36px;
      padding: 0 10px;
      background-color: #0A9AD7;
      transition: background-color .25s ease-in-out,
                  color .25s ease-in-out;

      @media (max-width: 575px) {
        padding: 0 5px;
      }
    }

    input {
      appearance: none;
      position: absolute;

      &:checked + span {
        background: rgba(130, 203, 255, 0.2) !important;
        box-shadow: 0 0 0 1px #0070C0;
        color: #0070C0;
      }

      &:disabled + span {
        background: #F1F1F1 !important;
        color: #9EA2A9;
      }
    }
  }
</style>
