<template>
  <div>
    <template v-if="linkPay">
      <h3 class="scs-subtitle scs-mb-15">Заказ успешно создан!</h3>

      <div class="text-center">
        <a :href="linkPay" class="scs-btn scs-btn--small mt-3" target="_blank">Оплатить</a>
      </div>
    </template>

    <template v-if="!linkPay">
      <form @submit.prevent="(privacyPolicy) ? submit() : false">
        <div
          v-if="errorMessage"
          class="form__message form__message--error"
          v-html="errorMessage"
        ></div>

        <div class="scs-order">
          <div class="scs-order-grid-props">
            <template v-for="(item, index) in props">
              <Input
                v-model="item.value"
                :name="item.name"
                :type="item.type"
                :placeholder="item.placeholder"
                :required="item.required"
                :mask="item.mask"
                @input="(item.onInput) ? item.onInput($event, index) : ''"
                :key="`order-prop-${index}`"
                class="mb-4"
              />
            </template>
          </div>

          <div class="mb-4">
            <span style="color: red">*</span> - Обязательное поле для заполнения
          </div>

          <div class="scs-order-form-sum">
            <div
              class="delivery-item"
              :class="(activePay) ? 'delivery-item--active' : ''"
              @click.once="activePay = true"
            >
              <div
                class="delivery-item__img"
                :class="(activePay) ? 'delivery-item__img--active' : ''"
              ></div>
            </div>

            <div class="scs-order-sum mt-4">
              Итого: <span>{{ sum }} руб.</span>
            </div>
          </div>

          <div class="mt-2">
            <input
              type="submit"
              class="scs-btn"
              :class="
                (!privacyPolicy) ? 'scs-btn--disabled' : ''
              "
              value="Оплатить онлайн"
              v-if="!btnLoading"
            />

            <Preloader v-if="btnLoading"/>
          </div>

          <div class="scs-bottom-text mt-4">
            <label class="scs-checkbox">
              <input
                type="checkbox"
                v-model="privacyPolicy"
              />

              <span>
                <div>
                  Нажимая, вы даёте разрешение на
                  обработку персональных данных
                  и соглашаетесь с
                  правилами посещения центра
                </div>
              </span>
            </label>
          </div>
        </div>
      </form>
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import Input from '@/components/Input.vue';
import Preloader from '@/components/Preloader.vue';

export default {
  name: 'OrderForm',
  components: {
    Input,
    Preloader,
  },
  props: {
    duration: {
      type: Number,
      required: true,
    },
    time: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    tickets: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      errorMessage: '',
      btnLoading: false,
      linkPay: '',
      privacyPolicy: false,
      activePay: true,
      props: [
        {
          name: 'phone',
          type: 'text',
          placeholder: 'Номер телефона',
          required: true,
          value: '',
          mask: '+9 (999) 999-99-99',
          rule: /^\+[7] \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/,
          onInput: (e, index) => this.autoCorrection(e, index),
        },
        {
          name: 'name',
          type: 'text',
          placeholder: 'Ваше имя',
          required: true,
          value: '',
          mask: '',
        },
        {
          name: 'email',
          type: 'text',
          placeholder: 'Ваш E-mail',
          required: true,
          value: '',
          mask: '',
          rule: /^.+@.+\..{2,}$/,
        },
      ],
    };
  },
  computed: {
    apiUrl() {
      return this.$store.getters.getApiUrl;
    },
    checkForm() {
      const checkFields = this.props.every((item) => {
        if (item.rule && !item.rule.test(item.value)) {
          return false;
        }

        if (item.required && (item.value.length > 0 || item.value)) {
          return true;
        }

        if (!item.required) {
          return true;
        }

        return false;
      });

      return checkFields && this.privacyPolicy;
    },
    sum() {
      return this.tickets.reduce((sum, item) => sum + (item.count * item.price), 0);
    },
    dateStr() {
      const date = new Date(this.date);
      const day = (`0${date.getDate()}`).slice(-2);
      const month = (`0${date.getMonth() + 1}`).slice(-2);
      const year = date.getFullYear();

      return `${year}-${month}-${day}`;
    },
    timeStr() {
      const time = this.time.split('-')[0];
      return `${time}:00`;
    },
    dateTimeStr() {
      return `${this.dateStr}T${this.timeStr}`;
    },
  },
  methods: {
    autoCorrection(val, index) {
      this.props[index].value = (val[1] === '8') ? '+7 (' : val;
    },
    submit() {
      this.errorMessage = '';

      if (this.checkForm) {
        this.btnLoading = true;

        const url = new URL(this.apiUrl);
        url.searchParams.set('action', 'addOrder');

        const date = new Date(this.date).toLocaleString('ru', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        });

        const params = new FormData();

        this.props.forEach((item) => {
          params.append(`props[${item.name}]`, item.value);
        });

        params.append('props[date]', date);
        params.append('props[time]', this.time);

        this.tickets.forEach((item, index) => {
          params.append(`tickets[${index}][id]`, item.id);
          params.append(`tickets[${index}][count]`, item.count);
          params.append(`tickets[${index}][date]`, this.dateTimeStr);
        });

        axios
          .post(url, params, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            const { data } = response.data;

            if (!data.success) {
              this.errorMessage = data.message;
            } else {
              this.linkPay = data.link;
            }

            this.btnLoading = false;
          })
          .catch((error) => {
            this.errorMessage = 'Ошибка!';
            this.btnLoading = false;
            console.log(error);
          });
      } else {
        this.errorMessage = 'Заполните обязательные поля';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .scs-order-grid-props {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin-bottom: 15px;

    @media (max-width: 767px) {
      grid-template-columns: 1fr;
      gap: 15px;
    }
  }

  .scs-order-form-sum {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin: 30px 0;
    align-items: center;

    @media (max-width: 991px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 575px) {
      grid-template-columns: 1fr;
    }
  }

  .scs-order-sum {
    font-weight: 400;
    font-size: 27px;
    line-height: 32px;
    letter-spacing: -0.02em;
    color: #0A9AD7;
    font-family: 'SegoeUISemiBold',Arial,'PT Sans Caption';

    span {
      text-transform: uppercase;
      font-family: 'SegoeUILight',Arial,'PT Sans Caption';
      color: #181818;
      margin-left: 15px;
    }
  }

  .delivery-item {
    &__img {
      background-image: url(~@/assets/img/pay.jpg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 93px;
      opacity: .5;
      transition: opacity .25s ease-in-out;
      order: 1;

      &--active {
        opacity: 1;
      }

      @media (max-width: 575px) {
        background-position: left;
      }
    }

    &:hover,
    &--active {
      border-color: #0db2a17c;
    }
  }

  .scs-order {
    max-width: 100%;
    width: 1200px;
    font-size: 18px;
    line-height: 26px;
  }

  .scs-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.1em;
    color: #FFFFFF;
    background: #0B99D9;
    border-radius: 30px;
    box-shadow: 0px 9px 40px rgba(50, 50, 50, 0.3);
    width: 100%;
    max-width: 262px;
    height: 58px;
    padding: 0 5px;
    border: 0;
    outline: none;
    margin-bottom: 30px;
    transition: background-color .25s ease-in-out, color .25s ease-in-out;

    &:hover {
      background: #257CBB;
    }

    &--disabled {
      background: #9EA2A9;;

      &:hover {
        background: #9EA2A9;;
      }
    }
  }

  .scs-checkbox {

    input {
      display: none;

      &:checked + span:before {
        background-image: url(~@/assets/img/check.svg);
      }
    }

    span {
      display: inline-flex;
      font-size: 15px;
      line-height: 26px;

      &:before {
        content: '';
        width: 22px;
        height: 22px;
        flex-shrink: 0;
        margin-right: 10px;
        border: 1px solid #CECECE;
        border-radius: 5px;
        background-color: #fff;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
        margin-top: 3px;
      }

      a,
      a:hover {
        color: #0A9AD7;
      }
    }
  }
</style>
