<template>
  <div
    class="scs-block"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Block',
};
</script>

<style lang="scss" scoped>
  .scs-block {
    background: #FFFFFF;
    padding: 30px;
    flex-grow: 1;

    @media (max-width: 575px) {
      padding: 20px 15px;
    }
  }
</style>
