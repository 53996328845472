// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/prev.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/img/next.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".scs-calendar[data-v-78425e84]{width:100%;text-align:center;color:#181818}.scs-calendar__btn[data-v-78425e84]{width:28px;height:28px;border:1px solid #0a9ad7;border-radius:50%;background-position:50%;background-repeat:no-repeat;background-color:transparent;outline-color:#0a9ad7;vertical-align:middle}.scs-calendar__btn--prev[data-v-78425e84]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.scs-calendar__btn--next[data-v-78425e84]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.scs-calendar__title[data-v-78425e84]{font-size:20px;line-height:32px}.scs-calendar__td[data-v-78425e84]{font-size:12px;line-height:16px;padding:7px}@media(max-width:1199px){.scs-calendar__td[data-v-78425e84]{padding:5px}}@media(max-width:991px){.scs-calendar__td[data-v-78425e84]{padding:10px}}@media(max-width:575px){.scs-calendar__td[data-v-78425e84]{padding:5px}}.scs-calendar__td--head[data-v-78425e84]{padding-bottom:30px}@media(max-width:575px){.scs-calendar__td--head[data-v-78425e84]{padding-bottom:15px}}.scs-calendar__item[data-v-78425e84]{display:flex;align-items:center;justify-content:center;width:36px;height:36px;background:#0a9ad7;border-radius:5px;margin:0 auto;cursor:pointer}.scs-calendar__item--dadisabled[data-v-78425e84]{background:#f1f1f1!important;color:#9ea2a9}.scs-calendar__item--selected[data-v-78425e84]{background:rgba(130,203,255,.2)!important;border:1px solid #0070c0;color:#0070c0}.scs-calendar__item--empty[data-v-78425e84]{opacity:0}@media(max-width:1199px){.scs-calendar__item[data-v-78425e84]{width:30px;height:30px}}@media(max-width:991px){.scs-calendar__item[data-v-78425e84]{width:36px;height:36px}}@media(max-width:575px){.scs-calendar__item[data-v-78425e84]{width:30px;height:30px}}", ""]);
// Exports
module.exports = exports;
