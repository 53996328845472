<template>
  <div id="by-ticket">
    <template v-if="loading || loadingDays">
      <div class="scs-loading">
        <Preloader></Preloader>
      </div>
    </template>

    <section class="scs-tickets">
      <div class="container">
        <h2 class="scs-title">
          Купить билет на сайте
        </h2>

        <!-- <h3 class="scs-title scs-title--small">
          Дневной "Аква-тариф" (без проживания) не действует до осени!
        </h3> -->

        <div class="scs-steps-grid">
          <div class="scs-steps-grid__item scs-steps-grid__item--step-0">
            <StepTitle>Шаг 1</StepTitle>

            <h3 class="scs-title scs-title--small">ВЫБОР ТАРИФА</h3>

            <RadioTariff
              :list="tariffList"
              v-model="props.tariff"
              @input="resetCountTickets()"
            ></RadioTariff>
          </div>

          <template v-if="this.props.tariff">
            <div class="scs-steps-grid__item scs-steps-grid__item--step-1">
              <StepTitle>Шаг 2</StepTitle>

              <Block>
                <h3 class="scs-subtitle">Дата посещения</h3>

                <Calendar
                  v-model="props.date"
                  :max-loading="maxLoading"
                  :workload-list="workloadList"
                  @loading="loadingDays = $event"
                  class="scs-mb-30"
                ></Calendar>

                <div class="scs-weight-700 scs-color-gray scs-mb-15">Загруженность:</div>

                <template v-for="(item, index) in workloadList">
                  <WorkloadItem
                    :name="item.name"
                    :color="item.color"
                    :key="`workload-item-${index}`"
                  ></WorkloadItem>
                </template>
              </Block>
            </div>

            <div class="scs-steps-grid__item scs-steps-grid__item--step-2">
              <StepTitle>Шаг 3</StepTitle>

              <Block>
                <h3 class="scs-subtitle">Продолжительность посещения и время захода</h3>

                <RadioDuration
                  :list="durationList"
                  v-model="props.duration"
                  class="scs-mb-30"
                  @input="resetCountTickets()"
                ></RadioDuration>

                <div class="scs-size-small scs-color-gray scs-mb-15">
                  Выберите интервал времени, в который вам необходимо подойти
                  на рецепцию термального центра и получить браслет:
                </div>

                <RadioTime
                  :list="timeListComp"
                  v-model="props.time"
                  class="scs-mb-30"
                ></RadioTime>

                <template v-if="maxCount > 0">
                  <template v-for="(item, index) in tiketsSelect">
                    <TicketBlock
                      :name="item.name"
                      :img="item.img"
                      :age="item.age"
                      :price="item.price"
                      :description="item.description"
                      :price-text="item.priceText"
                      :type="item.type"
                      :max-count="maxCount"
                      :max-child="maxTiketsChild"
                      :sum-count-tickets="sumCountTickets"
                      :min-count="item.min"
                      v-model="item.count"
                      :key="`tiket-${index}`"
                    ></TicketBlock>
                  </template>

                  <template v-if="infoText.text1">
                    <InfoBlock
                      class="scs-mb-15"
                    >
                      <div v-html="infoText.text1"></div>
                    </InfoBlock>
                  </template>

                  <template v-if="infoText.text2">
                    <InfoBlock>
                      <div v-html="infoText.text2"></div>
                    </InfoBlock>
                  </template>
                </template>
              </Block>
            </div>
          </template>

          <template v-if="tiketsSelectCountNoEmpty.length > 0">
            <div class="scs-steps-grid__item scs-steps-grid__item--step-3">
              <StepTitle>Шаг 4</StepTitle>

              <Block>
                <h3 class="scs-subtitle">Заполните данные и оплатите билет</h3>

                <Order
                  :duration="props.duration"
                  :time="timeSelect"
                  :date="dateSelect"
                  :tickets="tiketsSelectCountNoEmpty"
                ></Order>

                <OrderForm
                  :duration="props.duration"
                  :time="timeSelect"
                  :date="dateSelect"
                  :tickets="tiketsSelectCountNoEmpty"
                ></OrderForm>
              </Block>
            </div>
          </template>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios';
import Preloader from '@/components/Preloader.vue';
import StepTitle from '@/components/StepTitle.vue';
import Calendar from '@/components/Calendar.vue';
import Block from '@/components/Block.vue';
import WorkloadItem from '@/components/WorkloadItem.vue';
import RadioDuration from '@/components/RadioDuration.vue';
import RadioTariff from '@/components/RadioTariff.vue';
import RadioTime from '@/components/RadioTime.vue';
import TicketBlock from '@/components/TicketBlock.vue';
import InfoBlock from '@/components/InfoBlock.vue';
import Order from '@/components/Order.vue';
import OrderForm from '@/components/OrderForm.vue';

export default {
  name: 'App',
  components: {
    Preloader,
    StepTitle,
    Calendar,
    Block,
    WorkloadItem,
    RadioDuration,
    RadioTariff,
    RadioTime,
    TicketBlock,
    InfoBlock,
    Order,
    OrderForm,
  },
  data() {
    return {
      workloadList: [
        {
          name: 'низкая',
          color: '#BDE9FF',
          minCount: 71,
        },
        {
          name: 'средняя',
          color: '#0A9AD7',
          minCount: 31,
        },
        {
          name: 'высокая',
          color: '#f095a8',
          minCount: 1,
        },
        {
          name: 'мест нет',
          color: '#CECECE',
          minCount: 0,
        },
      ],
      props: {
        date: '',
        duration: 1,
        time: '',
        tariff: '',
      },
      tikets: [],
      infoText: {},
      durationList: [
        {
          name: '1 час',
          value: 1,
        },
        {
          name: '2 часа',
          value: 2,
        },
        {
          name: '4 часа',
          value: 4,
        },
        {
          name: 'безлимит',
          value: 0,
        },
      ],
      timeIntervalList: [
        {
          value: '09:00-10:00',
          duration: [1, 2, 4, 0],
        },
        {
          value: '10:00-11:00',
          duration: [1, 2, 4, 0],
        },
        {
          value: '11:00-12:00',
          duration: [1, 2, 4, 0],
        },
        {
          value: '12:00-13:00',
          duration: [1, 2, 4, 0],
        },
        {
          value: '13:00-14:00',
          duration: [1, 2, 4, 0],
        },
        {
          value: '14:00-15:00',
          duration: [1, 2, 4, 0],
        },
        {
          value: '15:00-16:00',
          duration: [1, 2, 4, 0],
        },
        {
          value: '16:00-17:00',
          duration: [1, 2, 0],
        },
        {
          value: '17:00-18:00',
          duration: [1, 2, 0],
        },
        {
          value: '18:00-19:00',
          duration: [1, 0],
        },
        {
          value: '19:00-20:00',
          duration: [],
        },
      ],
      tariffList: [],
      maxCountList: {},
      loading: false,
      loadingDays: false,
      maxLoading: 100,
      api: {
        path: '/bitrix/services/main/ajax.php',
        components: 'seoprostor:tickets',
        mode: 'class',
      },
    };
  },
  watch: {
    timeListComp(value) {
      const find = value.find((item) => !item.disabled);
      this.props.time = (find) ? find.name : '';
    },
    dateSelect(newVal, oldVal) {
      if (oldVal !== '') this.getLoading();
    },
  },
  computed: {
    apiUrl() {
      return this.$store.getters.getApiUrl;
    },
    selectNowDay() {
      const date = new Date();
      date.setHours(0, 0, 0, 0);

      const selectDate = new Date(this.props.date);
      selectDate.setHours(0, 0, 0, 0);

      return (+date === +selectDate);
    },
    timeIntervalListStr() {
      return this.timeIntervalList.map((item) => item.value);
    },
    timeList() {
      const time = this.timeIntervalList.filter((item) => {
        const index = item.duration.indexOf(this.props.duration);
        return index !== -1;
      });

      return time.map((item) => item.value);
    },
    timeListComp() {
      const hours = (`0${new Date().getHours()}`).slice(-2);

      return this.timeList.map((item) => {
        let disabled = (this.selectNowDay && hours > item);
        const emptyTikets = this.maxCountList[item];
        const findWorkload = this.workloadList.find((workload) => workload.minCount <= emptyTikets);
        const color = (findWorkload) ? findWorkload.color : '';

        if (emptyTikets === 0) {
          disabled = true;
        }

        return {
          name: item,
          value: item,
          disabled,
          color,
        };
      });
    },
    dateSelect() {
      return this.props.date;
    },
    timeSelect() {
      return this.props.time;
    },
    tiketsSelect() {
      return this.tikets.filter((item) => item.duration === this.props.duration
                                          && item.tariff === this.props.tariff);
    },
    sumCountTickets() {
      if (this.maxCount > 0) {
        return this.tiketsSelect.reduce((sum, ticket) => sum + ticket.count, 0);
      }

      return 0;
    },
    tiketsSelectCountNoEmpty() {
      if (this.maxCount > 0) {
        return this.tiketsSelect.filter((item) => item.count > 0);
      }

      return [];
    },
    maxCount() {
      return (this.maxCountList[this.props.time]) ? this.maxCountList[this.props.time] : 0;
    },
    countTiketsAdult() {
      return this.tiketsSelectCountNoEmpty.reduce((total, item) => {
        const count = (item.type === 138) ? item.count : 0;
        return total + count;
      }, 0);
    },
    maxTiketsChild() {
      return this.countTiketsAdult * 3;
    },
  },
  methods: {
    getApiUrl() {
      const { protocol, hostname, port } = window.location;
      let urlAddress = `${protocol}//${hostname}`;
      if (port.length > 0) {
        urlAddress += `:${port}`;
      }

      const url = new URL(`${urlAddress}${this.api.path}`);
      url.searchParams.set('c', this.api.components);
      url.searchParams.set('mode', this.api.mode);

      return url.toString();
    },
    resetCountTickets() {
      this.tikets = this.tikets.map((item) => {
        const ticket = item;
        ticket.count = ticket.min;

        return ticket;
      });
    },
    getTikets() {
      this.loading = true;
      this.tikets = [];

      const url = new URL(this.apiUrl);
      url.searchParams.set('action', 'getList');

      axios
        .get(url)
        .then((response) => {
          this.tikets = response.data.data;
          this.resetCountTickets();
          this.loading = false;
          this.getInfoText();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTariffs() {
      this.loading = true;
      this.tariffList = [];

      const url = new URL(this.apiUrl);
      url.searchParams.set('action', 'getTariffs');

      axios
        .get(url)
        .then((response) => {
          this.tariffList = response.data.data;

          this.resetCountTickets();
          this.loading = false;
          this.getTikets();

          this.props.tariff = this.tariffList[0].value;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getInfoText() {
      this.loading = true;
      this.infoText = [];

      const url = new URL(this.apiUrl);
      url.searchParams.set('action', 'getInfoText');

      axios
        .get(url)
        .then((response) => {
          this.infoText = response.data.data;
          this.loading = false;
          this.getLoading();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getLoading() {
      this.loading = true;
      const start = `${this.props.date}T00:00:00Z`;
      const end = `${this.props.date}T23:59:59Z`;

      this.maxCountList = {
        '09:00-10:00': this.maxLoading,
        '10:00-11:00': this.maxLoading,
        '11:00-12:00': this.maxLoading,
        '12:00-13:00': this.maxLoading,
        '13:00-14:00': this.maxLoading,
        '14:00-15:00': this.maxLoading,
        '15:00-16:00': this.maxLoading,
        '16:00-17:00': this.maxLoading,
        '17:00-18:00': this.maxLoading,
        '18:00-19:00': this.maxLoading,
        '19:00-20:00': this.maxLoading,
        '20:00-21:00': this.maxLoading,
        '21:00-22:00': this.maxLoading,
      };

      this.getLoadingInterval(start, end);
    },
    getLoadingInterval(start, end) {
      const url = new URL(this.apiUrl);
      url.searchParams.set('action', 'getLoading');

      url.searchParams.set('start', start);
      url.searchParams.set('end', end);

      axios
        .get(url)
        .then((response) => {
          const timeLoading = response.data.data;

          timeLoading.forEach((item) => {
            const nextHour = String(parseInt(item.hour, 10) + 1).padStart(2, '0');
            const time = `${item.hour}:00-${nextHour}:00`;

            this.maxCountList[time] -= item.amount;
          });

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    const apiUrl = this.getApiUrl();
    this.$store.dispatch('setApiUrl', apiUrl);

    const now = new Date();
    const day = (`0${now.getDate()}`).slice(-2);
    const month = (`0${now.getMonth() + 1}`).slice(-2);
    const year = now.getFullYear();

    this.props.date = `${year}-${month}-${day}`;
    this.getTariffs();
  },
};
</script>

<style lang="scss">
  #by-ticket {
    position: relative;
  }

  .scs-loading {
    position: absolute;
    top: -15px;
    bottom: -15px;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, .7);
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .scs-tickets {
    min-height: 100vh;
    padding: 60px 0;
    background-image: url('~@/assets/img/bg.jpg');
    background-repeat: no-repeat;
    background-position: top left;
    background-size: cover;
    background-attachment: fixed;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.45);
    }

    @media (max-width: 575px) {
      padding: 30px 0;
    }
  }

  .scs-title {
    font-weight: 400;
    font-size: 49px;
    line-height: 59px;
    color: #FFFFFF;
    text-align: center;
    margin: 0 0 20px;
    font-family: 'SegoeUISemiBold',Arial,'PT Sans Caption';

    @media (max-width: 991px) {
      font-size: 39px;
      line-height: 49px;
    }

    @media (max-width: 767px) {
      font-size: 34px;
      line-height: 40px;
    }

    @media (max-width: 575px) {
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 30px;
    }

    &--small {
      font-size: 26px;
      margin: 20px 0;
      line-height: 1.2;

      @media (max-width: 991px) {
        font-size: 22px;
      }

      @media (max-width: 767px) {
        font-size: 18px;
      }
    }
  }

  .scs-subtitle {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: #181818;
    margin: 0 0 30px;
    text-align: center;
    font-family: 'SegoeUISemiBold',Arial,'PT Sans Caption';
  }

  .scs-steps-grid {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-template-areas: "step-0 step-0"
                         "step-1 step-2"
                         "step-3 step-3";
    gap: 30px;

    &__item {
      border-radius: 5px;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      &--step-0 {
        grid-area: step-0;
      }

      &--step-1 {
        grid-area: step-1;
      }

      &--step-2 {
        grid-area: step-2;
      }

      &--step-3 {
        grid-area: step-3;
      }
    }

    @media (max-width: 991px) {
      grid-template-columns: 1fr;
      grid-template-areas: "step-0"
                           "step-1"
                           "step-2"
                           "step-3";
    }

    @media (max-width: 575px) {
      margin: 0 -10px;
    }
  }

  .scs-size {

    &-small {
      font-size: 13px;
      line-height: 17px;
    }
  }
  .scs-weight-700 {
    font-weight: 700;
    font-family: 'SegoeUISemiBold',Arial,'PT Sans Caption';
  }
  .scs-color {

    &-blue {
      color: #0A9AD7;
    }

    &-gray {
      color: #9EA2A9;
    }
  }

  .scs-mb {

    &-15 {
      margin-bottom: 15px;
    }

    &-30 {
      margin-bottom: 30px;
    }
  }
</style>
