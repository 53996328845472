import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    apiUrl: null,
  },
  getters: {
    getApiUrl: (state) => state.apiUrl,
  },
  mutations: {
    setApiUrl: (state, val) => {
      state.apiUrl = val;
    },
  },
  actions: {
    setApiUrl: ({ commit }, val) => {
      commit('setApiUrl', val);
    },
  },
  modules: {
  },
});
