<template>
  <div class="scs-quantity">
    <button
      class="scs-quantity__btn"
      @click.prevent="minus()"
    >&ndash;</button>

    <input
      type="text"
      class="scs-quantity__input"
      :value="value"
      @input="input($event.target.value)"
    />

    <button
      class="scs-quantity__btn"
      @click.prevent="plus()"
    >+</button>
  </div>
</template>

<script>
export default {
  name: 'Quantity',
  props: {
    value: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    min: {
      type: Number,
      required: true,
    },
  },
  watch: {
    max() {
      this.input(this.value);
    },
    min() {
      this.input(this.value);
    },
  },
  methods: {
    checkNumber(value) {
      return /^-?[1-9]\d{0,2}$/.test(value);
    },
    minus() {
      let value = this.value - 1;
      value = (value < this.min) ? this.min : value;
      this.setValue(value);
    },
    plus() {
      let value = this.value + 1;
      value = (value > this.max) ? this.max : value;
      this.setValue(value);
    },
    input(val) {
      let value = (this.checkNumber(val)) ? +val : 0;

      if (value < this.min) {
        value = this.min;
      } else if (value > this.max) {
        value = this.max;
      }

      this.setValue(value);
    },
    setValue(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss" scoped>
  .scs-quantity {
    display: flex;
    align-items: center;

    &__btn {
      flex-shrink: 0;
      width: 38px;
      height: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #0B99D9;
      padding: 0 5px 4px;
      border: 1px solid #0B99D9;
      border-radius: 5px;
      cursor: pointer;
      font-size: 20px;
      line-height: 20px;
      background: transparent;
      outline-color: #0DB2A2;
    }

    &__input {
      width: 38px !important;
      height: 38px;
      border: 0;
      outline-color: #0B99D9;
      text-align: center;
      color: #0B99D9;
    }
  }
</style>
