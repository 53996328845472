<template>
  <div class="scs-step-title">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'StepTitle',
};
</script>

<style lang="scss" scoped>
  .scs-step-title {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 60px;
    padding: 5px;
    background: #0070C0;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    color: #FFFFFF;
    text-align: center;
    flex-shrink: 0;
  }
</style>
