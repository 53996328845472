<template>
  <div
    class="scs-info-block"
  >
    <img
      :src="require('@/assets/img/info.svg')"
      alt="info"
    />

    <div class="scs-size-small scs-color-gray">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoBlock',
};
</script>

<style lang="scss" scoped>
  .scs-info-block {
    display: flex;
    align-items: flex-start;
    gap: 10px;

    & > img {
      flex-shrink: 0;
    }

    & > div {
      align-self: center;
    }
  }
</style>
