<template>
  <div class="scs-tariff-radio">
    <template v-for="(item, index) in list">
      <label
        class="scs-radio"
        :key="`radio-tariff-${index}`"
      >
        <input
          type="radio"
          name="tariff"
          :value="item.value"
          :checked="(item.value === value)"
          @input="$emit('input', item.value)"
          :disabled="!item.active"
        >

        <span>{{ item.name }}</span>
      </label>
    </template>
  </div>
</template>

<script>
export default {
  name: 'RadioTariff',
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    list: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  .scs-tariff-radio {
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: 30px;

    @media (max-width: 575px) {
      grid-template-columns: 1fr;
      gap: 15px;
    }
  }

  .scs-radio {
    cursor: pointer;
    position: relative;
    margin-bottom: 0;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 18px;
      line-height: 1.5;
      text-align: center;
      color: #fff;
      border: 1px solid #FFF;
      border-radius: 5px;
      height: 64px;
      padding: 5px 15px;
      background: rgba(255, 255, 255, 0.20);
      transition: background-color .25s ease-in-out,
                  border-color .25s ease-in-out,
                  color .25s ease-in-out;

      @media (max-width: 991px) {
        font-size: 14px;
        height: 54px;
      }
    }

    &:hover {
      span {
        background: #CEEBF7;
        border-color: #0B99D9;
        color: #0070C0;
      }
    }

    input {
      appearance: none;
      position: absolute;

      &:checked + span {
        background: #CEEBF7;
        border-color: #0B99D9;
        color: #0070C0;
      }

      &:disabled + span {
        opacity: .7;
        background: rgba(255, 255, 255, 0.20);
        border-color: #FFF;
        color: #fff;
      }
    }
  }
</style>
