<template>
  <div
    class="scs-ticket"
  >
    <div
      class="scs-ticket__info"
    >
      <div class="scs-ticket__name">
        <img
          :src="img"
          :alt="name"
        />

        <div>
          {{ name }}

          <template v-if="age">
            <span>{{ age }}</span>
          </template>
        </div>
      </div>

      <template v-if="description">
        <span>{{ description }}</span>
      </template>
    </div>

    <div
      class="scs-ticket__quantity"
      :class="(description) ? 'scs-ticket__mb' : ''"
    >
      <Quantity
        :value="value"
        :max="max"
        :min="minCount"
        @input="$emit('input', $event);"
      ></Quantity>
    </div>

    <div
      class="scs-ticket__price"
      :class="(description) ? 'scs-ticket__mb' : ''"
    >
      {{ price }} руб.

      <template v-if="priceText">
        <span v-html="priceText"></span>
      </template>
    </div>

    <div class="scs-weight-700 scs-color-blue">
      Итого: {{ sum }} руб.
    </div>
  </div>
</template>

<script>
import Quantity from '@/components/Quantity.vue';

export default {
  name: 'TicketBlock',
  components: {
    Quantity,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    img: {
      type: String,
      required: true,
    },
    age: {
      type: String,
      required: false,
    },
    price: {
      type: Number,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
    minCount: {
      type: Number,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    priceText: {
      type: String,
      required: false,
    },
    maxCount: {
      type: Number,
      required: true,
      default: 0,
    },
    maxChild: {
      type: Number,
      required: true,
      default: 0,
    },
    sumCountTickets: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  computed: {
    sum() {
      return this.price * this.value;
    },
    isChild() {
      return this.type === 139;
    },
    max() {
      const max = this.maxCount - this.sumCountTickets + this.value;

      if (this.isChild && max > this.maxChild) {
        return this.maxChild;
      }

      return max;
    },
  },
};
</script>

<style lang="scss" scoped>
  .scs-ticket {
    display: grid;
    align-items: center;
    grid-template-columns: 190px 114px max-content;
    gap: 5px 40px;
    margin-bottom: 15px;

    &__mb {
      margin-bottom: 22px;

      @media (max-width: 400px) {
        margin-bottom: 0;
      }
    }

    &__info {
      color: #0A9AD7;
      text-align: center;

      span {
        font-family: 'SegoeUILight',Arial,'PT Sans Caption';
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        text-transform: none;
      }
    }

    &__name {
      background: rgba(11, 153, 217, 0.2);
      border: 1px solid #0B99D9;
      border-radius: 5px;
      display: flex;
      align-items: center;
      text-align: left;
      min-height: 62px;
      width: 100%;
      padding: 5px 15px;
      gap: 15px;

      & > img {
        width: 40px;
        height: 34px;
        object-fit: scale-down;
        object-position: center;
        flex-shrink: 0;
      }

      & > div {
        flex-grow: 1;
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
        text-transform: uppercase;
        color: #0A9AD7;
        font-family: 'SegoeUISemiBold',Arial,'PT Sans Caption';
        display: flex;
        flex-direction: column;
      }
    }

    &__price {
      display: flex;
      flex-direction: column;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      text-transform: uppercase;
      color: #0B99D9;
      font-family: 'SegoeUISemiBold',Arial,'PT Sans Caption';

      span {
        font-family: 'SegoeUILight',Arial,'PT Sans Caption';
        font-weight: 400;
        font-size: 10px;
      }

      @media (max-width: 575px) {
        text-align: left;
        margin-bottom: 0;
      }
    }

    @media (max-width: 767px) {
      gap: 5px 30px;
    }

    @media (max-width: 575px) {
      gap: 10px 15px;
      grid-template-columns: 1fr 140px;
      border-bottom: 1px solid #F1F1F1;
      padding-bottom: 15px;
    }

    @media (max-width: 400px) {
      gap: 10px;
      grid-template-columns: 1fr;
    }
  }
</style>
