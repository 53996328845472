<template>
  <table class="scs-calendar">
    <thead>
      <tr>
        <td
          class="scs-calendar__td scs-calendar__td--head"
        >
          <button
            class="scs-calendar__btn scs-calendar__btn--prev"
            @click="decrease"
          ></button>
        </td>

        <td
          colspan="5"
          class="scs-calendar__title scs-calendar__td--head"
        > {{monthes[month]}} {{year}} </td>

        <td
          class="scs-calendar__td scs-calendar__td--head"
        >
          <button
            class="scs-calendar__btn scs-calendar__btn--next"
            @click="increase"
          ></button>
        </td>
      </tr>

      <tr>
        <template v-for="(d, index) in day">
          <td
            class="scs-calendar__td"
            :key="`td-day-${index}`"
          >{{ d }}</td>
        </template>
      </tr>
    </thead>

    <tbody>
      <template v-for="(week, weekIndex) in calendar">
        <tr :key="`tr-${weekIndex}`">
          <template v-for="(day, index) in week">
            <td
              class="scs-calendar__td"
              :key="`td-${index}`"
            >
              <span
                class="scs-calendar__item"
                :class="[
                  (day.disabled) ? 'scs-calendar__item--dadisabled' : '',
                  (day.selected) ? 'scs-calendar__item--selected' : '',
                  (day.empty) ? 'scs-calendar__item--empty' : '',
                ]"
                :style="`background-color: ${day.color};`"
                @click="(!day.disabled && !day.empty) ? selectDay(day.index) : ''"
              >
                {{ day.index }}
              </span>
            </td>
          </template>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Calendar',
  props: {
    value: {
      type: String,
      required: true,
    },
    maxLoading: {
      type: Number,
      required: true,
    },
    workloadList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
      dFirstMonth: 1,
      day: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
      monthes: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
      weekends: [],
      date: new Date(),
      maxLoadingList: {},
    };
  },
  computed: {
    apiUrl() {
      return this.$store.getters.getApiUrl;
    },
    dlast() {
      return new Date(this.year, this.month + 1, 0).getDate();
    },
    startDay() {
      const month = String(this.month + 1).padStart(2, '0');
      const day = String(this.dFirstMonth).padStart(2, '0');
      return `${this.year}-${month}-${day}T00:00:00Z`;
    },
    endDay() {
      const month = String(this.month + 1).padStart(2, '0');
      const day = String(this.dlast).padStart(2, '0');
      return `${this.year}-${month}-${day}T23:59:59Z`;
    },
    calendar() {
      const days = [];
      let week = 0;
      days[week] = [];
      const dateNow = new Date();
      dateNow.setHours(0, 0, 0, 0);
      const selectDate = new Date(this.value);
      selectDate.setHours(0, 0, 0, 0);

      for (let i = 1; i <= this.dlast; i += 1) {
        const dateDay = new Date(this.year, this.month, i);
        dateDay.setHours(0, 0, 0, 0);

        if (dateDay.getDay() === this.dFirstMonth) {
          week += 1;
          days[week] = [];
        }

        const a = { index: i };
        days[week].push(a);

        if (dateDay < dateNow) {
          a.disabled = true;
        }

        if (+dateDay === +selectDate) {
          a.selected = true;
        }

        const manthStr = String(this.month + 1).padStart(2, '0');
        const dayStr = String(i).padStart(2, '0');
        const dateStr = `${this.year}-${manthStr}-${dayStr}`;
        const emptyTikets = (this.maxLoadingList[dateStr])
          ? this.maxLoadingList[dateStr] : this.maxLoading;
        const findWorkload = this.workloadList.find((workload) => workload.minCount <= emptyTikets);
        const color = (findWorkload) ? findWorkload.color : '';

        a.color = color;
      }

      if (days[0].length > 0) {
        for (let i = days[0].length; i < 7; i += 1) {
          days[0].unshift({
            index: '',
            empty: true,
          });
        }
      }

      return days;
    },
  },
  methods: {
    decrease() {
      this.month -= 1;

      if (this.month < 0) {
        this.month = 12;
        this.month -= 1;
        this.year -= 1;
      }

      this.getLoadingDays();
    },
    increase() {
      this.month += 1;

      if (this.month > 11) {
        this.month = -1;
        this.month += 1;
        this.year += 1;
      }

      this.getLoadingDays();
    },
    selectDay(selectDay) {
      const day = (`0${selectDay}`).slice(-2);
      const month = (`0${this.month + 1}`).slice(-2);

      this.$emit('input', `${this.year}-${month}-${day}`);
    },
    getLoadingDays() {
      const start = this.startDay;
      const end = this.endDay;

      this.maxLoadingList = {};

      this.$emit('loading', true);

      const url = new URL(this.apiUrl);
      url.searchParams.set('action', 'getLoadingDays');

      url.searchParams.set('start', start);
      url.searchParams.set('end', end);

      axios
        .get(url)
        .then((response) => {
          response.data.data.forEach((item) => {
            this.maxLoadingList[item.day] = this.maxLoading - item.amount;
          });

          this.$emit('loading', false);
        })
        .catch((error) => {
          console.log(error);
          this.$emit('loading', false);
        });
    },
  },
  created() {
    this.getLoadingDays();
  },
};
</script>

<style lang="scss" scoped>
  .scs-calendar {
    width: 100%;
    text-align: center;
    color: #181818;

    &__btn {
      width: 28px;
      height: 28px;
      border: 1px solid #0A9AD7;
      border-radius: 50%;
      background-position: center;
      background-repeat: no-repeat;
      background-color: transparent;
      outline-color: #0A9AD7;
      vertical-align: middle;

      &--prev {
        background-image: url(~@/assets/img/prev.svg);
      }

      &--next {
        background-image: url(~@/assets/img/next.svg);
      }
    }

    &__title {
      font-size: 20px;
      line-height: 32px;
    }

    &__td {
      font-size: 12px;
      line-height: 16px;
      padding: 7px;

      @media (max-width: 1199px) {
        padding: 5px;
      }

      @media (max-width: 991px) {
        padding: 10px;
      }

      @media (max-width: 575px) {
        padding: 5px;
      }

      &--head {
        padding-bottom: 30px;

        @media (max-width: 575px) {
          padding-bottom: 15px;
        }
      }
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      background: #0A9AD7;
      border-radius: 5px;
      margin: 0 auto;
      cursor: pointer;

      &--dadisabled {
        background: #F1F1F1 !important;
        color: #9EA2A9;
      }

      &--selected {
        background: rgba(130, 203, 255, 0.2) !important;
        border: 1px solid #0070C0;
        color: #0070C0;
      }

      &--empty {
        opacity: 0;
      }

      @media (max-width: 1199px) {
        width: 30px;
        height: 30px;
      }

      @media (max-width: 991px) {
        width: 36px;
        height: 36px;
      }

      @media (max-width: 575px) {
        width: 30px;
        height: 30px;
      }
    }
  }
</style>
