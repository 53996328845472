<template>
  <div class="scs-duration-radio">
    <template v-for="(item, index) in list">
      <label
        class="scs-radio"
        :key="`radio-type-${index}`"
      >
        <input
          type="radio"
          name="duration"
          :value="item.value"
          :checked="(item.value === value)"
          @input="$emit('input', item.value)"
        >

        <span>{{ item.name }}</span>
      </label>
    </template>
  </div>
</template>

<script>
export default {
  name: 'RadioDuration',
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    list: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  .scs-duration-radio {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;

    @media (max-width: 575px) {
      gap: 10px;
    }
  }

  .scs-radio {
    cursor: pointer;
    position: relative;
    margin-bottom: 0;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 12px;
      line-height: 28px;
      text-align: center;
      color: #181818;
      border: 1px solid #181818;
      border-radius: 5px;
      height: 36px;
      padding: 0 20px;
      transition: background-color .25s ease-in-out,
                  border-color .25s ease-in-out,
                  color .25s ease-in-out;
    }

    input {
      appearance: none;
      position: absolute;

      &:checked + span {
        background: rgba(11, 153, 217, .2);
        border-color: #0A9AD7;
        color: #0A9AD7;
      }
    }
  }
</style>
