<template>
  <div class="scs-order">
    <p class="scs-order__title">Ваш заказ</p>

    <div class="scs-order__table">
      <div class="scs-order__name">Состав заказа:</div>
      <div>
        <template v-for="(item, index) in tickets">
          <div
            :key="`ticket-${index}`"
            class="mb-3"
          >
            {{ item.name }} {{ durationStr }}
            ({{ item.count }} шт)
          </div>
        </template>
      </div>

      <div class="scs-order__name">Дата посещения:</div>
      <div>
        {{ new Date(date).toLocaleString("ru", {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        }) }}
      </div>

      <div class="scs-order__name">Время посещения:</div>
      <div>
        {{ time }}
      </div>

      <div class="scs-order__name">Сумма:</div>
      <div class="scs-order__sum">
        <strong>{{ sum }} руб.</strong>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Order',
  props: {
    duration: {
      type: Number,
      required: true,
    },
    time: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    tickets: {
      type: Array,
      required: true,
    },
  },
  computed: {
    durationStr() {
      if (this.duration > 0) {
        return `${this.duration} ${(this.duration === 1) ? 'час' : 'часа'}`;
      }

      return 'безлимит';
    },
    sum() {
      return this.tickets.reduce((sum, item) => sum + item.count * item.price, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
  .scs-order {
    border: 2px solid #0B99D9;
    border-radius: 10px;
    padding: 30px;
    max-width: 750px;
    margin: 0 auto 30px;

    &__title {
      font-weight: 700;
      font-size: 17px;
      line-height: 26px;
      letter-spacing: 1.712px;
      text-transform: uppercase;
      text-align: center;
      color: #0B99D9;
      font-family: 'SegoeUISemiBold',Arial,'PT Sans Caption';
      margin-bottom: 30px;
    }

    &__table {
      font-weight: 300;
      font-size: 19px;
      line-height: 28px;
      color: #323232;
      display: grid;
      grid-template-columns: 200px 1fr;
      gap: 20px;

      @media (max-width: 575px) {
        grid-template-columns: 1fr;
        gap: 0;
      }
    }

    &__name {
      font-family: 'SegoeUISemiBold',Arial,'PT Sans Caption';
      font-weight: 700;
      text-align: right;

      & + div {
        @media (max-width: 575px) {
          margin-bottom: 15px;
        }
      }

      @media (max-width: 575px) {
        text-align-last: left;
      }
    }

    &__sum {
      font-family: 'SegoeUISemiBold',Arial,'PT Sans Caption';
      font-weight: 700;
      text-transform: uppercase;

      @media (max-width: 575px) {
        margin-bottom: 0 !important;
      }
    }

    @media (max-width: 575px) {
      padding: 20px 15px;
    }
  }
</style>
